import DefList from 'src/components/FullPatientData/PatientWidgetBody/DefList/DefList';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    DefList,
  },
};
